import React from 'react'
import { Link } from 'gatsby'

interface FooterProps {

}

const Footer: React.FunctionComponent<FooterProps> = () => (
  <footer className="bg-primary text-white">
    <div className="container text-center py-4">
      © 2020 Creative Ilk | <Link to="/privacy-policy/" className="text-white">Privacy Policy</Link>
    </div>
  </footer>
)

export default Footer
