import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import BootstrapNavbar from "react-bootstrap/Navbar"
import BootstrapNav from "react-bootstrap/Nav"
import BootstrapNavDropdown from "react-bootstrap/NavDropdown"

interface NavbarProps {

}

interface QueryData {
  logo: {
    publicURL: string
  },
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const query = graphql`
query {
  logo: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "logo.svg" }) {
    publicURL
  }
  site: site {
    siteMetadata {
      title
    }
  }
}
`

const Navbar: React.FunctionComponent<NavbarProps> = () => {
  const data: QueryData = useStaticQuery(query)

  return (
    <BootstrapNavbar className="bg-primary navbar-dark" expand="md">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={data.logo.publicURL} alt={data.site.siteMetadata.title} height={50} />
        </Link>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <BootstrapNav className="w-100 justify-content-around text-white">
            <BootstrapNavDropdown id="nav-tools-dropdown" title="Products" className="text-white">
              <Link className="dropdown-item text-secondary" to="/products/celldb/">Spreadsheet API</Link>
              <Link className="dropdown-item text-secondary" to="/products/dmarc/">DMARC Analytics</Link>
            </BootstrapNavDropdown>

            <BootstrapNavDropdown id="nav-tools-dropdown" title="Free tools" className="text-white">
              <Link className="dropdown-item text-secondary" to="/tools/statistical-significance-calculator/">A/B test calculator</Link>
              <Link className="dropdown-item text-secondary" to="/tools/email-ab-test-calculator/">Email test calculator</Link>
              <Link className="dropdown-item text-secondary" to="/tools/css-inliner/">CSS inliner</Link>
            </BootstrapNavDropdown>
            <BootstrapNav.Item>
              <Link className="nav-link text-white" to="/blog/">
                Blog
              </Link>
            </BootstrapNav.Item>
            <BootstrapNav.Item>
              <Link className="nav-link text-white" to="/about-us/" >
                About Us
              </Link>
            </BootstrapNav.Item>
          </BootstrapNav>
        </BootstrapNavbar.Collapse>
      </div>
    </BootstrapNavbar>
  )
}

export default Navbar
